import App from '../../src/desktop/containers/App/App.js';
import React from 'react';
import Home from './../desktop/containers/Home/Loadable';
import RedisMapping from '../RedisMappingLoadable';
import Checkout from '../desktop/components/Checkout/Loadable';
import MyAccount from './../desktop/containers/MyAccount/Loadable';
import ForgotPassword from './../desktop/containers/ForgotPassword/Loadable';
import ResetPassword from './../desktop/containers/ResetPassword/Loadable';
import HTO from '../own-days/views/desktop/HTO/HTO';
import Membership from '../own-days/views/desktop/Membership/Membership';
import Redirect from '../CommonComponents/Redirect/Redirect';
import CartLoadable from '../desktop/components/MainNav/NewCart/CartLoadable';
import Sharktank from '../CommonComponents/Sharktank/Sharktank';
import SelectLenses from '../own-days/views/desktop/SelectLenses/SelectLenses';
import UploadPrescription from '../own-days/views/desktop/UploadPrescription/UploadPrescription';
import PaymentPage from '../own-days/views/desktop/PaymentPage/PaymentPage';
import PaymentSuccess from '../own-days/views/desktop/PaymentSuccess/PaymentSuccess';
import CorporateInformationPage from '../own-days/views/desktop/CorporateInformationPage/CorporateInformationPage';
import ServicesPage from '../own-days/views/desktop/ServicesPage/ServicesPage';
import DontKnowPowerPage from '../own-days/views/desktop/DontKnowPowerPage/DontKnowPowerPage';
import TermsConditionsPage from '../own-days/views/desktop/TermsConditionsPage/TermsConditionsPage';
import PrivacyPolicyPage from '../own-days/views/desktop/PrivacyPolicyPage/PrivacyPolicyPage';
import FaqPage from '../own-days/views/desktop/FaqPage/FaqPage';
import CarePage from '../own-days/views/desktop/CarePage/CarePage';
import NewsPage from '../own-days/html/News.jsx';
import PrescriptionPage from '../own-days/views/desktop/PrescriptionPage/PrescriptionPage';
import GiftCards from '../own-days/views/desktop/GiftCards/GiftCards';
import AboutReferral from '../own-days/views/desktop/AboutReferral/AboutReferral';
import WishlistPage from '../own-days/views/desktop/WishlistPage/WishlistPage';
import OdRetryPayment from '../own-days/views/desktop/RetryPayment/OdRetryPayment';
import AddPrescription from '../own-days/views/desktop/AddPrescription/AddPrescription';
import ContactHTML from '../own-days/html/contact';
import HoroscopeHTML from '../own-days/html/HoroscopeHTML';
import ColorContactlensHTML from '../own-days/html/ColorContactlensHTML';
import ClearSunglassesHTML from '../own-days/html/ClearSunglassesHTML';
import CinnamorollHTML from '../own-days/html/CinnamorollHTML';
import BundleSaleHTML from '../own-days/html/BundleSaleHTML';
import AiruitemHTML from '../own-days/html/AirultemHTML';
import StoresHTML from '../own-days/html/StoresHTML';
import MembershipTermsHTML from '../own-days/html/MembershipTermsHTML';
import ParkwayParadeHTML from '../own-days/html/ParkwayParadeHTML';
import CausewayPointHTML from '../own-days/html/CausewayPointHTML';
import WoodleighMallStoreHTML from '../own-days/html/WoodleighMallStoreHTML';
import Lunar2023NewYearOperatingHoursHTML from '../own-days/html/Lunar2023NewYearOperatingHoursHTML';
import Lunar2022NewYearOperatingHoursHTML from '../own-days/html/Lunar2022NewYearOperatingHoursHTML';
import TakashimayaPremiumConceptStoreHTML from '../own-days/html/TakashimayaPremiumConceptStoreHTML';
import EmailServerHTML from '../own-days/html/EmailServerHTML';
import UrgentSystemHTML from '../own-days/html/UrgentSystemHTML';
import BugisJunctionStoreHTML from '../own-days/html/BugisJunctionStoreHTML';
import UrgentSystemAugustHTML from '../own-days/html/UrgentSystemAugustHTML';
import Lunar2021NewYearOperatingHoursHTML from '../own-days/html/Lunar2021NewYearOperatingHoursHTML';
import MBSPremiumConceptHTML from '../own-days/html/MBSPremiumConceptHTML';
import UrgentSystemFebruaryHTML from '../own-days/html/UrgentSystemFebruaryHTML';
import GiftCardsHTML from '../own-days/html/GiftCardsHTML';
import EyeCampHTML from '../own-days/html/EyeCampHTML';
import GundamHTML from '../own-days/html/GundamHTML';
import GundamOnlineOrderHTML from '../own-days/html/GundamOnlineOrderHTML';
import GundamAnniversaryHTML from '../own-days/html/GundamAnniversaryHTML';
import CollectionsHTML from '../own-days/html/CollectionsHTML';
import WhatsAppAccountHTML from '../own-days/html/WhatsAppAccountHTML';
import FacebookAccountHTML from '../own-days/html/FacebookAccountHTML';
import HowToUseHTML from '../own-days/html/HowToUseHTML';
import ContactLensNaturalHTML from '../own-days/html/ContactLensNaturalHTML';
import ContactLensPetalHTML from '../own-days/html/ContactLensPetalHTML';
import ContactLensVelvetHTML from '../own-days/html/ContactLensVelvetHTML';
import EcoFriendlySpectacleFramesHTML from '../own-days/html/EcoFriendlySpectacleFramesHTML';
import WearGundamHTML from '../own-days/html/WearGundamHTML';
import KimetsuCollectionHTML from '../own-days/html/KimetsuCollectionHTML';
import KuromiCollectionHTML from '../own-days/html/KuromiCollectionHTML';
import MemoryMetalHTML from '../own-days/html/MemoryMetalHTML';
import MimolletCollectionHTML from '../own-days/html/MimolletCollectionHTML';
import ClearVisionHTML from '../own-days/html/ClearVisionHTML';
import ClearVisionDailyHTML from '../own-days/html/ClearVisionDailyHTML';
import ClearVisionDailyAdvanceHTML from '../own-days/html/ClearVisionDailyAdvanceHTML';
import ClearVisionDailyBlueHTML from '../own-days/html/ClearVisionDailyBlueHTML';
import ClearVisionGuideHTML from '../own-days/html/ClearVisionGuideHTML';
import ClearVisionMonthlyHTML from '../own-days/html/ClearVisionMonthlyHTML';
import ClearVisionMonthlyAdvanceHTML from '../own-days/html/ClearVisionMonthlyAdvanceHTML';
import OwndaysMeetsJunHaganHTML from '../own-days/html/OwndaysMeetsJunHaganHTML';
import OwndaysMeetsYoheiShinomiyaHTML from '../own-days/html/OwndaysMeetsYoheiShinomiyaHTML';
import OwndaysMeetsAkiyoNohuchiHTML from '../own-days/html/OwndaysMeetsAkiyoNohuchiHTML';
import OwndaysMeetsTakanoriGomiHTML from '../own-days/html/OwndaysMeetsTakanoriGomiHTML';
import OwndaysMeetsRyuKoshinoHTML from '../own-days/html/OwndaysMeetsRyuKoshinoHTML';
import OwndaysMeetsKeitaSuzukiHTML from '../own-days/html/OwndaysMeetsKeitaSuzukiHTML';
import OwndaysMeetsShuzoNagumoHTML from '../own-days/html/OwndaysMeetsShuzoNagumoHTML';
import OwndaysMeetsHitoshiUedaHTML from '../own-days/html/OwndaysMeetsHitoshiUedaHTML';
import OwndaysMeetsChocomooHTML from '../own-days/html/OwndaysMeetsChocomooHTML';
import OwndaysMeetsKenshiHirokaneHTML from '../own-days/html/OwndaysMeetsKenshiHirokaneHTML';
import OwndaysMeetsSabuHTML from '../own-days/html/OwndaysMeetsSabuHTML';
import OwndaysMeetsMikeHavenaarHTML from '../own-days/html/OwndaysMeetsMikeHavenaarHTML';
import OwndaysMeetsDawnYeohHTML from '../own-days/html/OwndaysMeetsDawnYeohHTML';
import OwndaysMeetsYuniHadiHTML from '../own-days/html/OwndaysMeetsYuniHadiHTML';
import OwndaysMeetsHamTranHTML from '../own-days/html/OwndaysMeetsHamTranHTML';
import OwndaysMeetsFujiwaraKatsuakiHTML from '../own-days/html/OwndaysMeetsFujiwaraKatsuakiHTML';
import PokemonCollectionsHTML from '../own-days/html/PokemonCollectionsHTML';
import SenichisakuFramesHTML from '../own-days/html/SenichisakuFramesHTML';
import SunglassesCollectionsHTML from '../own-days/html/SunglassesCollectionsHTML';
import SystemRequirementHTML from '../own-days/html/SystemRequirementHTML';
import ServicesHTML from '../own-days/html/ServicesHTML';
import ServicesWarrantyHTML from '../own-days/html/ServicesWarrantyHTML';
import LensGuideHTML from '../own-days/html/LensGuideHTML';
import LensReplacementHTML from '../own-days/html/LensReplacementHTML';
import ServicesOnlineStoreHTML from '../own-days/html/ServicesOnlineStoreHTML';
import RetailPriceHTML from '../own-days/html/RetailPriceHTML';
import ServicesStaffHTML from '../own-days/html/ServicesStaffHTML';
import ServicesCareHTML from '../own-days/html/ServicesCareHTML';
import OwndaysMeetsHirotadaOtotakeHTML from '../own-days/html/OwndaysMeetsHirotadaOtotakeHTML';
import SiteMapHTML from '../own-days/html/SiteMapHTML';
import ColorContactLensHowToUse from '../own-days/html/ColorContactLensHowToUse';
import OwndaysPlusHTML from '../own-days/html/OwndaysPlusHTML';
import OwndaysSnapHTML from '../own-days/html/OwndaysSnapHTML';
import OwndaysPcHTML from '../own-days/html/OwndaysPcHTML';
import ShingoAibaHTML from '../own-days/html/ShingoAibaHTML.jsx';
import AnniversaryCollection10thHTML from '../own-days/html/AnniversaryCollection10thHTML.jsx';
import OwndaysMeets201408HTML from '../own-days/html/OwndaysMeets201408HTML.jsx';
import InformationHTML from '../own-days/html/InformationHTML.jsx';
import Login from '../desktop/containers/LoginSignup/Login.jsx';
import Signup from '../desktop/containers/LoginSignup/Signup.jsx';
import Gundam5Zeon from '../own-days/views/desktop/Gundam5Zeon/Gundam5Zeon.jsx';
import OwndaysRevampsBrandHTML from '../own-days/html/OwndaysRevampsBrandHTML.jsx';
import GundamZakuhead from '../own-days/views/desktop/GundamZakuhead/GundamZakuhead.jsx';
import HarryPotter from '../own-days/views/desktop/HarryPotter/HarryPotter.jsx';
import OwndaysProgressiveCPHTML from '../own-days/html/OwndaysProgressiveCPHTML.jsx';
import ToaPayohStore from '../own-days/html/ToaPayohStore.jsx';
import Huawei2 from '../own-days/views/desktop/Huawei2/Huawei2.jsx';
import OrthoKLensHTML from '../own-days/html/OrthoKLensHTML.jsx';
import VivoCityEvent from '../own-days/CommonComponents/VivoCityEvent/index.jsx';

const routes = [
  {
    component: Sharktank,
    path: '/quiz_front_page',
  },
  {
    component: App,
    routes: [
      // Home page
      { path: '/', exact: true, component: Home },
      { path: '/sg/en', exact: true, component: Home },

      // Auth Route and Reset and Fogot Password
      { path: '/(sg/en)?/customer/account/login', component: Login },
      { path: '/(sg/en)?/customer/account/signup', component: Signup },
      { path: '/customer/account/resetpassword', exact: true, component: ResetPassword },
      { path: '/customer/account/forgotpassword', exact: true, component: ForgotPassword },

      // Checkout Routes
      { path: '/cart', exact: true, component: CartLoadable },
      { path: '/checkout/onepage', component: Checkout }, // For Select Address
      { path: '/payment-page', component: PaymentPage },
      { path: '/retry-payment', component: OdRetryPayment },
      { path: '/(payment-success|payment-confirm)', component: PaymentSuccess },

      // Book eye test
      { path: '/bookeyetest', component: HTO },

      { path: '/select-lenses/:id?', component: SelectLenses },
      { path: '/upload-prescription/:id?', component: UploadPrescription },
      { path: '/add-prescription', component: AddPrescription },

      { path: '/customer/*', component: MyAccount },

      { path: '/gift-cards', component: GiftCards },
      { path: '/about-referral', component: AboutReferral },
      { path: '/(sg/en)?/news/gundam5-zakuhead', component: GundamZakuhead },
      { path: '/home-try-on-program-*', component: () => <Redirect to="/bookeyetest" /> },
      {
        path: '/(home-eye-check-up.html|home-eye-check-up-for-rs-5-plus-get-a-rs-1200-voucher.html|home-try-on-program.html|eyetest)',
        component: () => <Redirect to="/bookeyetest" />,
      },
      { path: '/wishlist', exact: true, component: WishlistPage },
      { path: '/corporate-information', exact: true, component: CorporateInformationPage },
      { path: '/terms', exact: true, component: TermsConditionsPage },
      { path: '/privacy', component: PrivacyPolicyPage },
      { path: '/faq', exact: true, component: FaqPage },
      { path: '/care', exact: true, component: CarePage },
      { path: '/prescription', exact: true, component: PrescriptionPage },
      { path: '/(sg/en)?/contact', component: ContactHTML },
      { path: '/(sg/en)?/contacts/color-contactlens/horoscope', component: HoroscopeHTML },
      {
        path: '/(sg/en)?/contacts/color-contactlens/how-to-use',
        component: ColorContactLensHowToUse,
      },
      { path: '/(sg/en)?/news/clear-sunglasses', component: ClearSunglassesHTML },
      { path: '/(sg/en)?/news/cinnamoroll', component: CinnamorollHTML },
      { path: '/(sg/en)?/news/bundle-sale', component: BundleSaleHTML },
      { path: '/(sg/en)?/news/enhanced-lens-offer', component: () => <Redirect to="/" /> },
      { path: '/(sg/en)?/news/airultem', component: AiruitemHTML },
      { path: '/(sg/en)?/news/500stores', component: StoresHTML },
      { path: '/(sg/en)?/news/progressive-cp', component: OwndaysProgressiveCPHTML },
      { path: '/(sg/en)?/membership-terms', component: MembershipTermsHTML },
      { path: '/(sg/en)?/information/97', component: ParkwayParadeHTML },
      { path: '/(sg/en)?/information/94', component: CausewayPointHTML },
      { path: '/(sg/en)?/information/432', component: WoodleighMallStoreHTML },
      { path: '/(sg/en)?/information/369', component: Lunar2023NewYearOperatingHoursHTML },
      {
        path: '/(sg/en)?/information/287',
        component: TakashimayaPremiumConceptStoreHTML,
      },
      { path: '/(sg/en)?/information/244', component: Lunar2022NewYearOperatingHoursHTML },
      { path: '/(sg/en)?/information/217', component: EmailServerHTML },
      { path: '/(sg/en)?/information/193', component: UrgentSystemHTML },
      { path: '/(sg/en)?/information/184', component: BugisJunctionStoreHTML },
      { path: '/(sg/en)?/information/162', component: UrgentSystemAugustHTML },
      { path: '/(sg/en)?/information/113', component: Lunar2021NewYearOperatingHoursHTML },
      { path: '/(sg/en)?/information/112', component: MBSPremiumConceptHTML },
      { path: '/(sg/en)?/information/111', component: UrgentSystemFebruaryHTML },
      { path: '/(sg/en)?/information/5001', component: OwndaysRevampsBrandHTML },
      { path: '/(sg/en)?/information/shops/1043', component: ToaPayohStore },
      { path: '/(sg/en)?/information', component: InformationHTML },
      { path: '/(sg/en)?/gift-ticket', component: GiftCardsHTML },
      { path: '/(sg/en)?/company/eyecamp', component: EyeCampHTML },
      { path: '/(sg/en)?/collabo/gundam/pj-snap', component: GundamHTML },
      {
        path: '/(sg/en)?/collabo/gundam/pj-head/reservation',
        component: GundamOnlineOrderHTML,
      },
      { path: '/(sg/en)?/collabo/gundam/pj-head', component: GundamAnniversaryHTML },
      { path: '/(sg/en)?/account/whatsapp', component: WhatsAppAccountHTML },
      { path: '/(sg/en)?/account/facebook', component: FacebookAccountHTML },
      { path: '/(sg/en)?/contact-lens-guide', component: HowToUseHTML },
      {
        path: '/(sg/en)?/contacts/color-contactlens/natural',
        component: ContactLensNaturalHTML,
      },
      { path: '/(sg/en)?/contacts/color-contactlens/petal', component: ContactLensPetalHTML },
      {
        path: '/(sg/en)?/contacts/color-contactlens/velvet',
        component: ContactLensVelvetHTML,
      },
      {
        path: '/(sg/en)?/news/eco2xy',
        component: EcoFriendlySpectacleFramesHTML,
      },
      {
        path: '/(sg/en)?/news/gundam',
        component: WearGundamHTML,
      },
      {
        path: '/(sg/en)?/news/huawei',
        component: () => <Redirect to="/news/huawei2" />,
      },
      {
        path: '/(sg/en)?/news/huawei2',
        component: Huawei2,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201408',
        component: OwndaysMeets201408HTML,
      },
      {
        path: '/(sg/en)?/news/owndays-plus2023',
        component: () => <Redirect to="/news/owndays-plus" />,
      },
      {
        path: '/(sg/en)?/news/shingo-aiba',
        component: ShingoAibaHTML,
      },
      {
        path: '/(sg/en)?/news/10th-anniversary-collection',
        component: AnniversaryCollection10thHTML,
      },
      {
        path: '/(sg/en)?/news/kimetsu',
        component: KimetsuCollectionHTML,
      },
      {
        path: '/(sg/en)?/news/kuromi',
        component: KuromiCollectionHTML,
      },
      {
        path: '/(sg/en)?/news/memorymetal',
        component: MemoryMetalHTML,
      },
      {
        path: '/(sg/en)?/news/mi-mollet',
        component: MimolletCollectionHTML,
      },
      {
        path: '/(sg/en)?/contacts/orthok-contactlens',
        component: OrthoKLensHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-plus',
        component: OwndaysPlusHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-snap',
        component: OwndaysSnapHTML,
      },
      {
        path: '/(sg/en)?/news/owndayspc',
        component: OwndaysPcHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/daily',
        component: ClearVisionDailyHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/daily-advance',
        component: ClearVisionDailyAdvanceHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/daily-blue',
        component: ClearVisionDailyBlueHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/how-to-use',
        component: ClearVisionGuideHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/monthly',
        component: ClearVisionMonthlyHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens/monthly-advance',
        component: ClearVisionMonthlyAdvanceHTML,
      },
      {
        path: '/(sg/en)?/news/christmas-happenings',
        component: () => <Redirect to="/" />,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201312',
        component: OwndaysMeetsJunHaganHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201401',
        component: OwndaysMeetsYoheiShinomiyaHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201402',
        component: OwndaysMeetsAkiyoNohuchiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201403',
        component: OwndaysMeetsAkiyoNohuchiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201404',
        component: OwndaysMeetsTakanoriGomiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201905',
        component: OwndaysMeetsHirotadaOtotakeHTML,
      },
      {
        path: '/(sg/en)?/sitemap',
        component: SiteMapHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201405',
        component: OwndaysMeetsRyuKoshinoHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201406',
        component: OwndaysMeetsKeitaSuzukiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201407',
        component: OwndaysMeetsShuzoNagumoHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-hitoshi-ueda',
        component: OwndaysMeetsHitoshiUedaHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201409',
        component: OwndaysMeetsChocomooHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201410',
        component: OwndaysMeetsKenshiHirokaneHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201506',
        component: OwndaysMeetsSabuHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201610',
        component: OwndaysMeetsMikeHavenaarHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201701',
        component: OwndaysMeetsDawnYeohHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201702-2',
        component: OwndaysMeetsYuniHadiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201702-3',
        component: OwndaysMeetsHamTranHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201807',
        component: OwndaysMeetsFujiwaraKatsuakiHTML,
      },
      {
        path: '/(sg/en)?/news/pokemon',
        component: PokemonCollectionsHTML,
      },
      {
        path: '/(sg/en)?/news/senichisaku',
        component: SenichisakuFramesHTML,
      },
      {
        path: '/(sg/en)?/news/sunglasses',
        component: SunglassesCollectionsHTML,
      },
      {
        path: '/(sg/en)?/contacts/clear-contactlens',
        component: ClearVisionHTML,
      },
      { path: '/news/free-myopia-control-lens', component: () => <Redirect to="/" /> },
      {
        path: '/(sg/en)?/services/warranty',
        component: ServicesWarrantyHTML,
      },
      {
        path: '/(sg/en)?/services/lens',
        component: LensGuideHTML,
      },
      {
        path: '/(sg/en)?/services/lens-replacement',
        component: LensReplacementHTML,
      },
      {
        path: '/(sg/en)?/services/online-store/frequency',
        component: DontKnowPowerPage,
      },
      {
        path: '/(sg/en)?/services/onlinestore',
        component: ServicesOnlineStoreHTML,
      },
      {
        path: '/(sg/en)?/services/price',
        component: RetailPriceHTML,
      },
      {
        path: '/(sg/en)?/services/staff',
        component: ServicesStaffHTML,
      },
      {
        path: '/(sg/en)?/services/care',
        component: ServicesCareHTML,
      },
      {
        path: '/(sg/en)?/services',
        component: ServicesHTML,
      },
      {
        path: '/(sg/en)?/requirement',
        component: SystemRequirementHTML,
      },
      {
        path: '/(sg/en)?/news/harry-potter',
        component: HarryPotter,
      },
      {
        path: '/(sg/en)?/news/gundam5-zeon',
        component: Gundam5Zeon,
      },
      {
        path: '/(sg/en)?/news/owndays-contactlens-sg',
        component: () => <Redirect to="/contacts/clear-contactlens" />,
      },
      {
        path: '/(sg/en)?/news/owndays-contactlens-sg/daily',
        component: () => <Redirect to="/contacts/clear-contactlens/daily" />,
      },
      {
        path: '/(sg/en)?/news/owndays-contactlens-sg/monthly',
        component: () => <Redirect to="/contacts/clear-contactlens/monthly" />,
      },
      {
        path: '/(sg/en)?/news/owndays-contactlens-sg/daily-advance',
        component: () => <Redirect to="/contacts/clear-contactlens/daily-advance" />,
      },
      {
        path: '/(sg/en)?/news/owndays-contactlens-sg/monthly-advance',
        component: () => <Redirect to="/contacts/clear-contactlens/monthly-advance" />,
      },
      {
        path: '/(sg/en)?/news/color-contactlens',
        component: () => <Redirect to="/contacts/color-contactlens" />,
      },
      {
        path: '/(sg/en)?/news/color-contactlens/natural',
        component: () => <Redirect to="/contacts/color-contactlens/natural" />,
      },
      {
        path: '/(sg/en)?/news/color-contactlens/horoscope',
        component: () => <Redirect to="/contacts/color-contactlens/horoscope" />,
      },
      {
        path: '/(sg/en)?/news/color-contactlens/petal',
        component: () => <Redirect to="/contacts/color-contactlens/petal" />,
      },
      {
        path: '/(sg/en)?/news/color-contactlens/velvet',
        component: () => <Redirect to="/contacts/color-contactlens/velvet" />,
      },
      {
        path: '/(sg/en)?/news/ortho-k-lens',
        component: () => <Redirect to="/contacts/orthok-contactlens" />,
      },
      { path: '/(sg/en)?/contacts/color-contactlens', component: ColorContactlensHTML },
      { path: '/(sg/en)?/news/vivocity-event', component: VivoCityEvent },
      { path: '/(sg/en)?/brands', component: CollectionsHTML },
      { path: '/services', exact: true, component: ServicesPage },
      { path: '/news', component: NewsPage },
      { path: '/account', component: Membership },
      { component: RedisMapping },
    ],
  },
];
export { routes };
