import React, { useState } from 'react';
import './Footer.scss';
import PropTypes from 'prop-types';

import AuthModal from 'desktop/components/AuthModal/AuthModalLoadable';
import AuthModalNewLoadable from '../AuthModalNew/AuthModalNewLoadable';
import AuthPanel from '../AuthPanel/AuthPanel';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getSetCookie from '../../../cookie';
import * as registerActions from '../../../actionCreators/auth';
import * as cartActions from '../../../actionCreators/cart';
import * as commonCallsActionCreators from '../../../actionCreators/common';
import LangPopup from '../../../own-days/CommonComponents/LangPopup/LangPopup';
import SocialMediaLinks from '../../../own-days/SocialMediaLinks/SocialMediaLinks';
import { COUNTRIES_DESKTOP } from '../../../CommonComponents/Footer/footer';

const countries = COUNTRIES_DESKTOP;

const Footer = props => {
  const {
    dataLocale,
    redisCommonData = {},
    login,
    askUserLogin,
    userInfo,
    mainNavigationData,
  } = props;

  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [showLangModal, setShowCLangModal] = useState(false);

  const {
    PRODUCTS,
    FRAMES,
    SUNGLASSES,
    CONTACT_LENS,
    CONTACT_LENSES,
    CAMPAIGNS,
    PURCHASE,
    LENS_GUIDE,
    OWNDAYS_CUSTOMER_GUARANTEES,
    ABOUT,
    SHOPS,
    SERVICES,
    LENS_REPLACEMENT,
    MEMBERSHIP,
    EYE_CAMP,
    SUPPORT,
    CONTACT_US,
    FAQS,
    OPERATING_HOURS,
    COPYRIGHT,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    CONTACT_US_PHONE,
    OPENING_HOURS_TIME,
    NEWS,
    INFORMATION,
  } = dataLocale;

  const isNewAuthPopUpAvailable = !!(
    redisCommonData?.NEW_AUTH_POPUP_DESKTOP &&
    JSON.parse(redisCommonData?.NEW_AUTH_POPUP_DESKTOP)?.enable === 'ON'
  );

  const linksBlocks = [
    {
      title: PRODUCTS,
      links: [
        {
          title: FRAMES,
          link: mainNavigationData?.footer?.FRAMES,
        },
        {
          title: SUNGLASSES,
          link: mainNavigationData?.footer?.SUNGLASSES,
        },
        {
          title: CONTACT_LENSES,
          link: mainNavigationData?.footer?.CONTACT_LENS,
        },
        {
          title: LENS_GUIDE,
          link: mainNavigationData?.footer?.LENS_GUIDE,
        },
      ],
    },
    {
      title: SERVICES,
      links: [
        {
          title: OWNDAYS_CUSTOMER_GUARANTEES,
          link: mainNavigationData?.footer?.CUSTOMER_GUARANTEES,
        },
        {
          title: LENS_REPLACEMENT,
          link: mainNavigationData?.footer?.LENS_REPLACEMENT,
        },
        {
          title: "Care+",
          link: "/sg/en/services/care",
        },
      ],
    },
    {
      title: ABOUT,
      links: [
        {
          title: SHOPS,
          link: mainNavigationData?.footer?.SHOPS,
        },
        {
          title: SERVICES,
          link: mainNavigationData?.footer?.SERVICES,
        },
        {
          title: MEMBERSHIP,
          link: mainNavigationData?.footer?.MEMBERSHIP,
        },
        {
          title: EYE_CAMP,
          link: mainNavigationData?.footer?.EYE_CAMP,
        },
        {
          title: 'Latest Campaigns',
          link: mainNavigationData?.footer?.NEWS,
        },
        {
          title: 'Career',
          link: 'https://www.odsaiyou.com/sg/',
        },
      ],
    },
    {
      title: SUPPORT,
      links: [
        {
          title: CONTACT_US,
          isChatBot: false,
          link: mainNavigationData?.footer?.CONTACT_US,
        },
        {
          title: FAQS,
          link: mainNavigationData?.footer?.FAQS,
        },
      ],
    },
  ];

  const showStrip =
    redisCommonData.SHOW_NEW_STRIP !== undefined
      ? JSON.parse(redisCommonData.SHOW_NEW_STRIP)
      : false;

  const hideAuthPopUp = () => {
    clearInterval(window.secondsTimer);
    setShowAuthPopUp(false);
  };

  const startChatBot = () => {
    const { chatBotParams } = window;

    chatBotParams.emailId = userInfo?.result?.email || '';
    chatBotParams.phoneNumber = userInfo?.result?.telephone || '';
    chatBotParams.sessionID = getSetCookie.getCookie('clientV1') || '';
    chatBotParams.env = process.env.BUILD_ENV === 'development' ? 'preprod' : 'prod';
    chatBotParams.apiClient = process.env.CLIENT_TYPE;

    window.Freshbots?.reloadContext();

    window.setTimeout(() => {
      if (JSON.parse(redisCommonData?.SPRINKLR_BOT_CONFIG)?.desktop_enabled === 'ON') {
        if (chatBotParams.emailId && window?.sprChat) {
          window.sprChat('openNewConversation', {
            conversationContext: {
              '_c_62a329b623cadb2fbf33b21f ': [chatBotParams.phoneNumber], // unique ID from contact us/chat with us, maybe phone number
              _c_62a32a2c23cadb2fbf33f020: ['TRUE'], // triggeredByChatwithUs
            },
            id: chatBotParams.phoneNumber, // MANDATORY, maybe phone number
          });
        } else if (window?.sprChat) window.sprChat('open');
      } else {
        window.Freshbots?.initiateChat();
        window.Freshbots?.showWidget(true);
      }
    }, 1000);
  };

  return (
    <footer className="app-footer" id="footer-container">
      <section className="app-footer-top">
        <div className="od-container od-container--lg">
          <div className="app-footer-top__layout">
            {linksBlocks.map(({ title, links }) => (
              <div key={title} className="app-footer-nav__block">
                <p className="app-footer-nav__title od-font-bold">{title}</p>
                {links.map(({ title, link, isSignup, isChatBot }) => {
                  if (isSignup) {
                    return (
                      <AuthPanel
                        key={title}
                        {...props}
                        authenticate={() => setShowAuthPopUp(true)}
                        dropdownPositionY="top"
                        loginStatus={login}
                        textClass="app-footer-nav__link od-font-reg"
                      >
                        {title}
                      </AuthPanel>
                    );
                  } else if (isChatBot && !showStrip?.enable) {
                    return (
                      <p
                        key={title}
                        className="app-footer-nav__link od-font-reg"
                        onClick={startChatBot}
                      >
                        {title}
                      </p>
                    );
                  }
                  return (
                    <a key={title} className="app-footer-nav__link od-font-reg" href={link}>
                      {title}
                    </a>
                  );
                })}
              </div>
            ))}
            <div className="app-footer-contact od-font-bold">
              <div>
                <p className="app-footer-contact__title">{CONTACT_US?.toUpperCase()}</p>
                <div className="app-footer-contact__call-block">
                  <p className="app-footer-contact__phone">{CONTACT_US_PHONE}</p>
                  <p className="app-footer-contact__hours">
                    {OPERATING_HOURS}
                    <br />
                    {OPENING_HOURS_TIME}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {mainNavigationData?.footer?.SOCIAL_LINKS && (
            <SocialMediaLinks
              device="desktop"
              socialLinksdata={mainNavigationData?.footer?.SOCIAL_LINKS}
            />
          )}
        </div>
      </section>
      <section className="app-footer-info">
        <div className="od-container od-container--lg od-font-bold">
          <p className="app-footer-info__copyright">{COPYRIGHT}</p>
          <div className="app-footer-info__links">
            <a className="app-footer-info__link" href={mainNavigationData?.footer?.PRIVACY_POLICY}>
              {PRIVACY_POLICY}
            </a>
            <div className="app-footer-info__divider">|</div>
            <a
              className="app-footer-info__link"
              href={mainNavigationData?.footer?.TERMS_AND_CONDITIONS}
            >
              {TERMS_AND_CONDITIONS}
            </a>
            <div className="app-footer-info__divider">|</div>
            <div className="app-footer-info__country" onClick={() => setShowCLangModal(true)}>
              <img alt="country" className="app-footer-info__country-img" src={countries[6].img} />
              <span>Singapore</span>
              <img
                alt="arrow"
                className="app-footer-info__country__toggle"
                src="https://www.owndays.com/images/icons/icon-down.svg"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        {showAuthPopUp &&
          (isNewAuthPopUpAvailable ? (
            <AuthModalNewLoadable
              allowSignUp
              handleModalClose={hideAuthPopUp}
              hideLogin={hideAuthPopUp}
              login={login}
              openTab="signup"
            />
          ) : (
            <AuthModal
              allowSignUp
              askUserLogin={askUserLogin}
              hideLogin={hideAuthPopUp}
              openTab="signup"
            />
          ))}
      </section>
      {showLangModal && (
        <LangPopup
          countries={countries}
          device="desktop"
          setShowModal={setShowCLangModal}
          showModal={showLangModal}
        />
      )}
    </footer>
  );
};

Footer.propTypes = {
  login: PropTypes.bool,
  redisCommonData: PropTypes.any,
  userInfo: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    login: state.common.login,
    dataLocale: state.locale,
    askUserLogin: state.common.askUserLogin,
    redisCommonData: state.common.redisCommonData,
    userInfo: state.common.userInfo,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
