import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { HEADER, HIGHLIGHTS, NOTES } from './constanst';
import '../../../theme/pages/campaign.scss';
import Modal from '../Modal/Modal';

const VivoCityEvent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'Pop-Up Event at VivoCity' },
  ];

  const handleTermsClick = terms => {
    setSelectedTerms(terms);
    setIsModalOpen(true);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="campaign-page campaign-page--vivocity-event">
        <div className="campaign-page__container">
          <h1 className="campaign-page__title">{HEADER.title}</h1>
        </div>
        <img className="campaign-page__thumbnail" src={HEADER.imgSrc} alt={HEADER.imgAlt} />
        <div className="campaign-page__container">
          <p
            className="campaign-page__text"
            dangerouslySetInnerHTML={{ __html: HEADER.duration }}
          />
          <p className="campaign-page__text">{HEADER.description}</p>
          {HIGHLIGHTS && (
            <>
              <h2 className="campaign-page__subtitle">Highlights</h2>
              <ul className="campaign-page__highlights">
                {HIGHLIGHTS.map((highlight, index) => (
                  <li key={index} className="campaign-page__highlights-li">
                    <img
                      className="campaign-page__highlights-img"
                      src={highlight.imgSrc}
                      alt={highlight.imgAlt}
                    />
                    <div className="campaign-page__highlights-details">
                      {highlight.title && (
                        <h3 className='mb-0' dangerouslySetInnerHTML={{ __html: highlight.title }} />
                      )}
                      {highlight.text?.map((item, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                      ))}
                      {highlight.terms && (
                        <button
                          className="campaign-page__highlights-terms"
                          onClick={() => handleTermsClick(highlight.terms)}
                        >
                          Terms & Conditions
                          <img
                            src="https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/icon-external-link.svg"
                            alt="Icon external link"
                          />
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          {NOTES && (
            <ul className="campaign-page__notes">
              {NOTES.map(note => <li key={note}>{note}</li>)}
            </ul>
          )}
        </div>
      </main>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="modal__title">Terms & Conditions</h2>
        <ul className="modal__terms">
          {selectedTerms.map((term, index) => (
            <li key={index}>{term}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default VivoCityEvent;
