const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/news';

export const NEWS_CATEGORIES = [
  {
    name: 'ALL',
    hash: '#all',
  },
  {
    name: 'OWNDAYS MEETS',
    hash: '#owndays-meets',
  },
  {
    name: 'Products',
    hash: '#products',
  },
  {
    name: 'Campaign',
    hash: '#campaign',
  },
];

export const PRODUCT_NEWS = [
  {
    slug: '/sg/en/news/harry-potter',
    title: 'Harry Potter × OWNDAYS',
    image: `${baseImgUrl}/harry-potter-owndays.webp`,
    category: 'Products',
    description:
      'Harry Potter × OWNDAYS eyewear collection inspired by meticulously crafted magical objects and symbolic patterns.',
    order: 41,
  },
  {
    slug: '/sg/en/news/gundam5-zakuhead',
    title: 'ZEON × OWNDAYS ZAKU HEAD CASE',
    image: `${baseImgUrl}/zeon-owndays=zaku-head-case.webp`,
    category: 'Products',
    description:
      'From Mobile Suit Gundam, one of the most iconic Japanese anime series that has transcended generations, comes the Zaku Head Case, an eyewear storage case modelled after Char Aznable’s custom Zaku in the largest-ever 1:7 scale.',
    order: 40,
  },
  {
    slug: '/sg/en/news/gundam5-zeon',
    title: 'ZEON EXCLUSIVE EYEGLASSES & SUNGLASSES',
    image: `${baseImgUrl}/zeon-owndays.webp`,
    category: 'Products',
    description:
      'From Mobile Suit Gundam, one of the most iconic Japanese anime series that has remained highly popular across generations, comes an eyewear collaboration focusing on the Principality of Zeon. The lineup consists of a spectacle model and a sunglass model.',
    order: 39,
  },
  {
    slug: '/sg/en/news/owndays-plus',
    title: 'OWNDAYS+',
    image: `${baseImgUrl}/owndays-plus.webp`,
    category: 'Products',
    description:
      'OWNDAYS aspires to present eyewear that is modest and a true reflection of the wearer’s personality. Without deliberate pursuit of design, A subtle, modest approach that brings the true spirit of Japanese craftsmanship to life. Where minimalist aesthetic and sublime quality meet efficient pricing, that’s OWNDAYS+.',
    order: 38,
  },
  {
    slug: '/sg/en/news/huawei2',
    title: 'OWNDAYS × HUAWEI Eyewear 2',
    image: `${baseImgUrl}/owndays-huawei-eyewear-2.webp`,
    category: 'Products',
    description:
      'The wildly successful OWNDAYS x HUAWEI Eyewear makes a return with significant improvements.',
    order: 37,
  },
  {
    slug: '/sg/en/news/shingo-aiba',
    title: 'SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION',
    image: `${baseImgUrl}/shingo-aiba.webp`,
    category: 'Products',
    description:
      'A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris.',
    order: 35,
  },
  {
    slug: '/sg/en/news/10th-anniversary-collection',
    title: '10th Anniversary Collection',
    image: `${baseImgUrl}/10th-anniversary.webp`,
    category: 'Products',
    description:
      'A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear.',
    order: 34,
  },
  {
    slug: '/sg/en/news/500stores',
    title: 'Celebrating 500 stores worldwide',
    image: `${baseImgUrl}/celebrating-500-stores-worldwide.webp`,
    category: 'Products',
    description:
      'A commemorative collection to mark the 500-store milestone, featuring frames in lightweight resin material that makes them a great comfort to wear. These made-in-Japan frames come in the oversized rectangle style and the classic Boston style and are available at an unprecedented price so customers can enjoy quality eyewear at a fraction of the usual retail price. This is our way of saying Thank You to all our customers for making this 500-store milestone a dream come true.',
    order: 33,
  },
  {
    slug: '/sg/en/news/owndays-snap',
    title: 'OWNDAYS SNAP',
    image: `${baseImgUrl}/owndays-snap.webp`,
    category: 'Products',
    description:
      'Transform the spectacles into sunglasses instantly by snapping on the SNAP LENS to the base frame. Besides, SNAP LENS is fitted with polarised lenses that can reduce glare from shiny surfaces such as the road and water surface to give you a more comfortable vision.',
    order: 32,
  },
  {
    slug: '/sg/en/news/memorymetal',
    title: 'Memory Metal',
    image: `${baseImgUrl}/memory-metal.webp`,
    category: 'Products',
    description:
      'A collection featuring Nitinol (NiTi) frames that are made using the latest ion plating (IP) process. It is able to retain its shape despite being bent by force.',
    order: 31,
  },
  {
    slug: '/sg/en/news/kuromi',
    title: 'Kuromi × OWNDAYS',
    image: `${baseImgUrl}/kuromi-owndays.webp`,
    category: 'Products',
    description:
      'An OWNDAYS x Kuromi crossover in support of the #KUROMIfy the World project. Transform into the best self you can ever be with these cute, accessory-like eyewear that are inspired by Kuromi and Baku!',
    order: 30,
  },
  {
    slug: '/sg/en/news/airultem',
    title: 'AIR Ultem',
    image: `${baseImgUrl}/air-uitem.webp`,
    category: 'Products',
    description:
      'Lightweight, flexible and durable because it’s made of ultem. Spectacle frames that are soft as a feather and light as the air and are unlikely to break even when bent.',
    order: 29,
  },
  {
    slug: '/sg/en/news/senichisaku',
    title: '千一作',
    image: `${baseImgUrl}/senichisaku.webp`,
    category: 'Products',
    description:
      'Sabae, a city located in the Fukui Prefecture, Japan, is widely regarded as the City of Eyewear. This is where Senichisaku frames are crafted, with every pair carefully put together by hand.',
    order: 28,
  },
  {
    slug: '/sg/en/news/eco2xy',
    title: 'eco²xy',
    image: `${baseImgUrl}/eco-2-xy.webp`,
    category: 'Products',
    description:
      'Eco-Friendly Spectacle Frames Made Using Renewable Raw Materials Derived From Castor Plant. Castor Plant is a renewable raw material that grows in wastelands. It is a perennial plant that does not cause deforestation. The use of fossil fuel is thus reduced during production, resulting in lower carbon emission. At OWNDAYS, we are committed to making our products as environmentally friendly as possible. ',
    order: 27,
  },
  {
    slug: '/sg/en/news/cinnamoroll',
    title: 'Cinnamoroll × OWNDAYS',
    image: `${baseImgUrl}/cinnamorall-owndays.webp`,
    category: 'Products',
    description:
      'content="An eyewear collection inspired by Cinnamoroll, a fluffy white male puppy, and his friends. Featuring frames modelled after Cinnamoroll, Milk, Corune and Poron with carefully thought-out accents iconic to each character.',
    order: 24,
  },
  {
    slug: '/sg/en/news/mi-mollet',
    title: 'mi-mollet × OWNDAYS',
    image: `${baseImgUrl}/mi-mollet-owndays.webp`,
    category: 'Products',
    description:
      'Explore the stylish spectacle frames by Naoko Okusa, a collaboration between mi-mollet and OWNDAYS, where fashion meets functionality effortlessly. Shop now.',
    order: 23,
  },
  {
    slug: '/sg/en/news/kimetsu',
    title: '[Demon Slayer: Kimetsu no Yaiba] Character Frame Collection',
    image: `${baseImgUrl}/demon-slayer.webp`,
    category: 'Products',
    description:
      'A collection featuring six spectacle frames inspired by the six characters, namely Tanjiro, Nezuko, Zenitsu, Inosuke, Giyu, Shinobu and Kyojuro from the anime television series Demon Slayer: Kimetsu no Yaiba. Each frame is a distinct representation of each of the six characters, with frame temples decorated in motifs depicting iconic patterns found on both the Nichirin Sword and the outfit of the respective character.',
    order: 22,
  },
  {
    slug: '/sg/en/news/gundam',
    title: 'GUNDAM × OWNDAYS',
    image: `${baseImgUrl}/gundam-owndays.webp`,
    category: 'Products',
    description:
      'Mobile suit GUNDAM × OWNDAYS! Wear the GUNDAM. 40th Anniversary Collaboration Project.',
    order: 21,
  },
  {
    slug: '/sg/en/news/clear-sunglasses',
    title: 'CLEAR SUNGLASSES COLLECTION',
    image: `${baseImgUrl}/clear-sunglasses-collection.webp`,
    category: 'Products',
    description:
      'Avoid getting suntanned through the eyes with Clear Sunglasses that offer 100% UV protection!',
    order: 20,
  },
  {
    slug: '/sg/en/news/owndayspc',
    title: 'OWNDAYS PC',
    image: `${baseImgUrl}/owndays-pc.webp`,
    category: 'Products',
    description: 'OWNDAYS PC - SAVE YOUR EYES, SHIELD YOUR EYES! Reduce up to 25% blue light!',
    order: 19,
  },
  {
    slug: '/sg/en/news/sunglasses',
    title: 'OWNDAYS SUNGLASSES COLLECTION 2022',
    image: `${baseImgUrl}/owndays-sunglasses-collection-2022.webp`,
    category: 'Products',
    description:
      'Shop 2022 OWNDAYS Sunglasses Collection, featuring an assortment of designs available at PHP 2,990~ per pair. All sunglasses block over 99% UV, even for those with non-tinted lenses. Protect your eyes from UV rays. Convert to prescription sunglasses at a top-up fee.',
    order: 18,
  },
];

export const OWNDAYS_MEETS_NEWS = [
  {
    slug: '/sg/en/news/owndays-meets-201905',
    title: '#19 HIROTADA OTOTAKE',
    image: `${baseImgUrl}/hirotada-ototake.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Our guest in Part 19 of OWNDAYS MEETS is Ototake Hirotada. We speak him about the “OTOTAKE PROJECT” in which he took up the challenge of walking with prosthetic legs developed using the latest technology. We also talk to him about his plans moving forward.',
    order: 17,
  },
  {
    slug: '/sg/en/news/owndays-meets-201807',
    title: '#17 FUJIWARA KATSUAKI',
    image: `${baseImgUrl}/fujiwara-katsuaki.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Our guest in Part 17 of OWNDAYS MEETS is Fujiwara Katsuaki, a former professional motorcycle racer who is currently the coach of the Kawasaki Racing Team.',
    order: 16,
  },
  {
    slug: '/sg/en/news/owndays-meets-201702-3',
    title: '#16 Ham Tran',
    image: `${baseImgUrl}/ham-tran.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Our guest in part 16 of OWNDAYS MEETS is award-winning Vietnamese-American film director, Ham Tran. We speak to him about the path he has taken so far in his career.',
    order: 15,
  },
  {
    slug: '/sg/en/news/owndays-meets-201702-2',
    title: '#15 Yuni Hadi',
    image: `${baseImgUrl}/yuni-hadi.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Our guest in part 14 of OWNDAYS MEETS is Yuni Hadi, a Singaporean film producer who is known in the Singapore art scene for actively promoting independent Singapore films. We speak to her about her career and her views on the future of the film industry.',
    order: 14,
  },
  {
    slug: '/sg/en/news/owndays-meets-201701',
    title: '#14 Dawn Yeoh',
    image: `${baseImgUrl}/dawn-yeoh.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Delve into the world of Dawn Yeoh, Singaporean artiste, in Part 14 of OWNDAYS MEETS. Uncover her career path, passion, and future aspirations in the arts and beyond.',
    order: 13,
  },
  {
    slug: '/sg/en/news/owndays-meets-201610',
    title: '#13 Mike Havenaar',
    image: `${baseImgUrl}/mike-havenaar.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'Our guest in part 13 of OWNDAYS MEETS is Mike Havenaar, a professional footballer who leads an active international career.',
    order: 12,
  },
  {
    slug: '/sg/en/news/owndays-meets-201506',
    title: '#12 SABU',
    image: `${baseImgUrl}/sabu.webp`,
    category: 'OWNDAYS MEETS',
    description:
      'We have SABU, a film director, as the 12th guest for OWNDAYS MEETS. He is the director, author and scriptwriter of the film “Ama no Chasuke”',
    order: 11,
  },
  {
    slug: '/sg/en/news/owndays-meets-201410',
    title: '#11 KENSHI HIROKANE',
    image: `${baseImgUrl}/kenshi-hirokane.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#11 KENSHI HIROKANE -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 10,
  },
  {
    slug: '/sg/en/news/owndays-meets-201409',
    title: '#10 Chocomoo',
    image: `${baseImgUrl}/chocomoo.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#10 Chocomoo -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 9,
  },
  {
    slug: '/sg/en/news/owndays-meets-201408',
    title: '#09 HITOSHI UEDA',
    image: `${baseImgUrl}/hitoshi-ueda.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#09 HITOSHI UEDA -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 8,
  },
  {
    slug: '/sg/en/news/owndays-meets-201407',
    title: '#08 SHUZO NAGUMO',
    image: `${baseImgUrl}/shuzo-nagumo.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#08 SHUZO NAGUMO -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 7,
  },
  {
    slug: '/sg/en/news/owndays-meets-201406',
    title: '#07 KEITA SUZUKI',
    image: `${baseImgUrl}/keita-suzuki.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#07 KEITA SUZUKI -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 6,
  },
  {
    slug: '/sg/en/news/owndays-meets-201405',
    title: '#06 Ryu Koshino',
    image: `${baseImgUrl}/ryu-koshino.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#06 RYU KOSHINO -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 5,
  },
  {
    slug: '/sg/en/news/owndays-meets-201404',
    title: '#05 Takanori Gomi',
    image: `${baseImgUrl}/takanori-gomi.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#05 TAKANORI GOMI -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 4,
  },
  {
    slug: '/sg/en/news/owndays-meets-201403',
    title: '#04 KEISUKE OKUNOYA',
    image: `${baseImgUrl}/keisuke-okunoya.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#04 KEISUKE OKUNOYA -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 3,
  },
  {
    slug: '/sg/en/news/owndays-meets-201402',
    title: '#03 AKIYO NOGUCHI',
    image: `${baseImgUrl}/akiyo-nogushi.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#03 AKIYO NOGUCHI -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 2,
  },
  {
    slug: '/sg/en/news/owndays-meets-201401',
    title: '#02 YOHEI SHINOMIYA',
    image: `${baseImgUrl}/yohei-shinomiya.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#02 YOHEI SHINOMIYA -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 1,
  },
  {
    slug: '/sg/en/news/owndays-meets-201312',
    title: '#01 JUN HAGAN',
    image: `${baseImgUrl}/jun-hagan.webp`,
    category: 'OWNDAYS MEETS',
    description:
      '#01 JUN HAGAN -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path.',
    order: 0,
  },
];

export const CAMPAING_NEWS = [
  {
    slug: '/sg/en/news/vivocity-event',
    title: 'Pop-Up Event at VivoCity',
    image: `${baseImgUrl}/vivocity-event/News.webp`,
    category: 'Campaign',
    description: `Join us at our Pop-Up Event at VivoCity Level 1 Atrium for unbeatable deals on our spectacle frames and try your luck at winning fabulous prizes (terms & conditions apply). Whether you are a fashion-forward person, a savvy shopper or just looking to refresh your eyewear, we have something special for everyone. See you there!`,
    order: 44,
  },
  {
    slug: '/sg/en/news/bundle-sale',
    title: 'OWNDAYS Contact Lens Promotion',
    image: `${baseImgUrl}/contact-lens-bundle-sale.webp`,
    category: 'Campaign',
    description: `Grab exclusive contact lens bundles: Get $10 off 4 boxes or $30 off 8 boxes of clear lenses and 1 box free for every 7 boxes of color lenses. While stocks last!`,
    order: 43,
  },
  {
    slug: '/sg/en/news/progressive-cp',
    title: 'OWNDAYS PROGRESSIVE GLASSES',
    image: `${baseImgUrl}/owndays-progressive-glasses.webp`,
    category: 'Campaign',
    description: `One pair of glasses to see far, intermediate and reading zones. Prices from $198 only. `,
    order: 42,
  },
];

export const NEWS_LIST = [...CAMPAING_NEWS, ...PRODUCT_NEWS, ...OWNDAYS_MEETS_NEWS].sort((a, b) => {
  // Use 0 as a fallback if order is undefined
  const orderA = a.order ?? 0;
  const orderB = b.order ?? 0;
  return orderB - orderA;
});
