export const HEADER = {
  title: 'Pop-Up Event at VivoCity',
  imgSrc: 'https://static.lenskart.com/media/owndays/img/news/vivocity-event/thumbnail.webp',
  imgAlt: 'Pop-Up Event at VivoCity',
  duration: '<strong>Duration : </strong>From 18 Sep to 29 Sep 2024',
  description:
    'Join us at our Pop-Up Event at VivoCity Level 1 Atrium for unbeatable deals on our spectacle frames and try your luck at winning fabulous prizes (terms & conditions apply). Whether you are a fashion-forward person, a savvy shopper or just looking to refresh your eyewear, we have something special for everyone. See you there!',
};

export const HIGHLIGHTS = [
  {
    imgSrc: 'https://static.lenskart.com/media/owndays/img/news/vivocity-event/event-1.webp',
    imgAlt: '70% OFF',
    title: 'Activity ① : <br/>Enjoy up to 70% off selected spectacle frames',
    terms: [
      'Offer is valid for selected items at the OWNDAYS pop-up event at VivoCity Atrium from 18 to 29 September 2024, while stocks last',
      'Items sold are not refundable nor exchangeable',
      'Items sold will not be covered by the OWNDAYS Customer Guarantees warranty programme',
      'There will be a one-time exceptional warranty on lens visual performance within 6 months of purchase; lens power based on doctor’s prescription (Dr Rx) will be excluded from this warranty coverage',
      'Regular top up fee applies for optional lens upgrades in which the standard 1-year warranty on lens visual performance will be valid',
      'Prescription glasses will only be ready for collection at the OWNDAYS VivoCity store 14 days from the date of purchase or when the OWNDAYS VivoCity store reopens, whichever is later',
      'Offer is not valid in conjunction with other discounts, vouchers and/or promotions',
      'The management reserves the right to amend the offer and the terms and conditions without prior notice',
    ],
  },
  {
    imgSrc: 'https://static.lenskart.com/media/owndays/img/news/vivocity-event/event-2.webp',
    imgAlt: 'WIN SUNGLASSES',
    title:
      'Activity ② : <br/>Spend min. $150 for a chance to grab a prize from the game booth, expect exciting prizes such as OWNDAYS sunglasses worth $78, limited edition eco-bags, exclusive merchandise and more!',
    terms: [
      'A valid receipt indicating a minimum spend of $150 at the OWNDAYS pop-up event at VivoCity Atrium from 18 to 29 September 2024 must be presented, with one chance to grab a prize from the game booth per receipt',
      'Each game will end as soon as the customer grabs a prize ticket or up to a maximum of 30 seconds whichever is earlier',
      'Each customer is entitled to one prize only; in the event the customer manages to grab more than one prize ticket from the game booth, they will have to select one most preferred prize from the ones they have grabbed',
      'Prizes while stocks last and must be redeemed on the spot and are not exchangeable once redeemed',
      'Prizes are not transferable or exchangeable for cash or other reward forms',
      'Prizes are not covered by the OWNDAYS Customer Guarantees warranty programme',
      'Customers who are not suitable to wear contact lenses or are not contact lens wearers but have grabbed the free 5-pairs-Daily-Contact-Lens voucher may opt to receive a free Lens Cleaner instead',
      'Voucher prizes are valid for the next purchase at OWNDAYS VivoCity store only and are subjected to the respective voucher terms and conditions',
      'The management reserves the right to amend or terminate the campaign and these terms and conditions without prior notice',
    ],
  },
  {
    imgSrc: 'https://static.lenskart.com/media/owndays/img/news/vivocity-event/event-3.webp',
    imgAlt: 'MASCOT PHOTO SPOT',
    title: 'Activity ③ : <br/>Capture your moment with Mega-Kuma!',
    text: [
      'Swing by the mascot photo spot for a fun and Instagram-worthy shot that captures the spirit of the event.',
      'And that’s not all—be sure to pick up an <strong>exclusive sticker pack for your kids</strong> when you drop by. These limited-edition goodies are available only while stocks last!',
    ],
  },
];

export const NOTES = [
  '*All images for illustration purposes only'
];
